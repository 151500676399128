import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { saveAs } from 'file-saver';
import { dataFormatter, dataFormatterWithoutNumber } from 'lib/string';
import XLSX from 'xlsx';
import Workbook from '../Workbook';
import DataType from 'constant/datatype';

interface Props {
  children?: string | JSX.Element;
  saveName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theader: any[];
  tBody: any[];
  ouputOriginString?: boolean;
}
const XlsxButton: FC<Props> = ({ theader, tBody, saveName, ouputOriginString, children, }: Props): JSX.Element => {
  const encrypt = (keyName, value) => {
    switch (keyName) {
      case 'customerName':
        let tmpValue = value.split('');

        //避免名稱為兩個字
        if (tmpValue.length == 2) {
          tmpValue[1] = 'O';
        }
        for (let i = 1; i < tmpValue.length - 1; i++) {
          tmpValue[i] = 'O';
        }

        value = tmpValue.join('');
        break;
    }
    return value;
  };

  const dataToXlsx = (theader: string[], tBody: string[]) => {
    var workbook = new Workbook();
    var result: string[][] = [];
    var tmp: string[] = [];
    theader = theader ? theader : [];
    tBody = tBody ? tBody : [];

    for (var i = 0; i < theader.length; i++) {
      var content = theader[i]['header'];
      if (Array.isArray(theader[i]['header'])) {
        content = theader[i]['header'].join('');
      }
      tmp.push(content);
    }
    result.push(tmp);

    for (var i = 0; i < tBody.length; i++) {
      tmp = [];
      for (var j = 0; j < theader.length; j++) {
        var value = tBody[i][theader[j]['columnKey']];
        if (ouputOriginString !== true) {
          value = dataFormatterWithoutNumber(value, theader[j]['dataType']);
        }
        if (theader[j]['dataType'] === DataType.NullAbleBoolean || theader[j]['dataType'] === DataType.Boolean) {
          value = dataFormatter(value, theader[j]['dataType']);
        }
        value = encrypt(theader[j]['columnKey'], value);
        tmp.push(value);
      }
      result.push(tmp);
    }

    const sheet = XLSX.utils.aoa_to_sheet(result);
    workbook.appendSheet(sheet);

    return workbook;
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        saveAs(dataToXlsx(theader, tBody).toBlob(), `${saveName}.xlsx`);
      }}
    >
      {children ? children : '匯出'}
    </Button>
  );
};
export default XlsxButton;
